















import { Component, Prop, Vue } from "vue-property-decorator";
import { GameBoardTileModel, TileCheckResultState } from "@/models/board";
import { BCol } from "bootstrap-vue";
import PictogramTileComponent from "@/components/board/PictogramTileComponent.vue";

@Component({
  components: {
    BCol,
    PictogramTileComponent,
  },
})
export default class GameBoardTileComponent extends Vue {
  @Prop({ required: true }) readonly gameBoardTile!: GameBoardTileModel;
  @Prop({ default: false }) readonly withBadge!: boolean;

  get showBadge(): boolean {
    return (
      this.withBadge &&
      this.gameBoardTile.checkResult.state !== TileCheckResultState.UNCHECKED
    );
  }

  get categoryColor() {
    return this.gameBoardTile.pictogramTile.category.colorClass;
  }

  get gameBoardTileClass(): string {
    switch (this.gameBoardTile.checkResult.state) {
      case TileCheckResultState.CORRECT: {
        return "overlay correct-result";
      }
      case TileCheckResultState.CORRECT_CATEGORY: {
        return "overlay correct-category";
      }
      case TileCheckResultState.UNCHECKED: {
        return "unchecked";
      }
      case TileCheckResultState.INCORRECT: {
        return "overlay incorrect";
      }
      default: {
        return "state-unknown border-danger";
      }
    }
  }

  get membersInCategory(): number | string {
    return this.gameBoardTile.checkResult.membersInCategory || "-";
  }
}
