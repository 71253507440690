import { render, staticRenderFns } from "./HelpComponent.vue?vue&type=template&id=fb6f59d4&"
import script from "./HelpComponent.vue?vue&type=script&lang=ts&"
export * from "./HelpComponent.vue?vue&type=script&lang=ts&"
import style0 from "./HelpComponent.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports