











































import { Component, Prop, Vue } from "vue-property-decorator";
import {
  BButton,
  BCol,
  BContainer,
  BIcon,
  BInputGroup,
  BInputGroupAppend,
  BForm,
  BRow,
  BFormInput,
} from "bootstrap-vue";
import { GameBoardTileModel } from "@/models/board";
import PictogramTileComponent from "@/components/board/PictogramTileComponent.vue";
import GameBoardTileComponent from "@/components/board/GameBoardTileComponent.vue";
import { gameBoardStore } from "@/store/board.vuex";
import CounterComponent from "@/components/board/CounterComponent.vue";

@Component({
  components: {
    BButton,
    BCol,
    BContainer,
    BIcon,
    BInputGroup,
    BInputGroupAppend,
    BForm,
    BFormInput,
    BRow,
    CounterComponent,
    GameBoardTileComponent,
    PictogramTileComponent,
  },
})
export default class WordInputComponent extends Vue {
  guess = "";
  @Prop({ default: true }) hidden!: boolean;

  get correctTiles(): Array<GameBoardTileModel> {
    return gameBoardStore.correctTiles;
  }

  get hint(): string {
    return gameBoardStore.wordHint;
  }

  get isWordSolved(): boolean {
    return gameBoardStore.isWordSolved;
  }

  showPictogramInput() {
    gameBoardStore.showPictogramInput();
  }

  submitWordGuess() {
    gameBoardStore.submitWordGuess(this.guess);
    if (!gameBoardStore.isWordSolved) {
      this.guess = "";
    }
  }
}
