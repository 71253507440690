










import { Component, Vue } from "vue-property-decorator";
import { BContainer } from "bootstrap-vue";
import { GameBoardTileModel } from "@/models/board";
import TileCategoryComponent from "@/components/board/TileCategoryComponent.vue";
import { gameBoardStore } from "@/store/board.vuex";

@Component({
  components: {
    BContainer,
    TileCategoryComponent,
  },
})
export default class SelectorComponent extends Vue {
  readonly categories: Array<Array<GameBoardTileModel>> =
    gameBoardStore.selectedTiles;
}
