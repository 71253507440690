import categories from "@/store/seeds/categories";
import { PictogramTileModel } from "@/models/board";

const tiles = new Set([
  // Category 1
  // **********
  new PictogramTileModel({
    order: 1,
    name: "Objekt, Verpackung, Karton",
    imageFilename: "category1/ObjektVerpackungKarton.png",
    category: categories.prideRed,
  }),
  new PictogramTileModel({
    order: 2,
    name: "Familie, Gesellschaft, Gruppe",
    imageFilename: "category1/FamilieGesellschaftGruppe.png",
    category: categories.prideRed,
  }),
  new PictogramTileModel({
    order: 3,
    name: "Arbeit, Handwerk",
    imageFilename: "category1/ArbeitHandwerk.png",
    category: categories.prideRed,
  }),
  new PictogramTileModel({
    order: 4,
    name: "Erholung, Sport ,Aktivität",
    imageFilename: "category1/ErholungSportAktivitaet.png",
    category: categories.prideRed,
  }),
  new PictogramTileModel({
    order: 5,
    name: "Tierwelt, Tier",
    imageFilename: "category1/TierweltTier.png",
    category: categories.prideRed,
  }),
  new PictogramTileModel({
    order: 6,
    name: "Pflanzenwelt, Pflanze",
    imageFilename: "category1/PflanzenweltPflanze.png",
    category: categories.prideRed,
  }),
  new PictogramTileModel({
    order: 7,
    name: "Literatur, Schrift, Buch",
    imageFilename: "category1/LiteraturSchriftBuch.png",
    category: categories.prideRed,
  }),
  new PictogramTileModel({
    order: 8,
    name: "Musik, Lied, Note",
    imageFilename: "category1/MusikLiedNote.png",
    category: categories.prideRed,
  }),
  new PictogramTileModel({
    order: 9,
    name: "Theater, Film, Kamera",
    imageFilename: "category1/TheaterFilmKamera.png",
    category: categories.prideRed,
  }),
  new PictogramTileModel({
    order: 10,
    name: "Kunst, Bildhauerei, Malerei, Zeichnen, Comics",
    imageFilename: "category1/KunstBildhauereiMalereiZeichnenComics.png",
    category: categories.prideRed,
  }),
  new PictogramTileModel({
    order: 11,
    name: "Fernsehen, Rundfunk, Serien",
    imageFilename: "category1/FernsehenRundfunkSerien.png",
    category: categories.prideRed,
  }),
  new PictogramTileModel({
    order: 12,
    name: "Titel, Marke",
    imageFilename: "category1/TitelMarke.png",
    category: categories.prideRed,
  }),
  new PictogramTileModel({
    order: 13,
    name: "Idee, Gedanke, Konzept",
    imageFilename: "category1/IdeeGedankeKonzept.png",
    category: categories.prideRed,
  }),
  new PictogramTileModel({
    order: 14,
    name: "Ausdruck, Zitat, Sprechen, Wort",
    imageFilename: "category1/AusdruckZitatSprechenWort.png",
    category: categories.prideRed,
  }),
  new PictogramTileModel({
    order: 15,
    name: "Ort, Land, Flagge",
    imageFilename: "category1/OrtLandFlagge.png",
    category: categories.prideRed,
  }),
  new PictogramTileModel({
    order: 16,
    name: "Gebäude, Bauwerk, Stadt",
    imageFilename: "category1/GebaeudeBauwerkStadt.png",
    category: categories.prideRed,
  }),
  new PictogramTileModel({
    order: 17,
    name: "Datum, Ereignis, Tag",
    imageFilename: "category1/DatumEreignisTag.png",
    category: categories.prideRed,
  }),
  new PictogramTileModel({
    order: 18,
    name: "Ferien, Geburtstag, Feiertag",
    imageFilename: "category1/FerienGeburtstagFeiertag.png",
    category: categories.prideRed,
  }),
  new PictogramTileModel({
    order: 19,
    name: "Wasserfahrzeug, Meer, Schwimmen",
    imageFilename: "category1/WasserfahrzeugMeerSchwimmen.png",
    category: categories.prideRed,
  }),
  new PictogramTileModel({
    order: 20,
    name: "Luftfahrzeug, Fluglinie, Fliegen",
    imageFilename: "category1/LuftfahrzeugFluglinieFliegen.png",
    category: categories.prideRed,
  }),
  new PictogramTileModel({
    order: 21,
    name: "Landfahrzeug, Auto, Fahren",
    imageFilename: "category1/LandfahrzeugAutoFahren.png",
    category: categories.prideRed,
  }),
  new PictogramTileModel({
    order: 22,
    name: "Werkzeuge",
    imageFilename: "category1/Werkzeuge.png",
    category: categories.prideRed,
  }),
  new PictogramTileModel({
    order: 23,
    name: "Spiele, Spielzeug",
    imageFilename: "category1/SpieleSpielzeug.png",
    category: categories.prideRed,
  }),
  new PictogramTileModel({
    order: 24,
    name: "Kleidung, Accessoires, Kostüm",
    imageFilename: "category1/KleidungAccessoiresKostuem.png",
    category: categories.prideRed,
  }),
  new PictogramTileModel({
    order: 25,
    name: "Essen, Lebensmittel, Essbares",
    imageFilename: "category1/EssenLebensmittelEssbares.png",
    category: categories.prideRed,
  }),
  new PictogramTileModel({
    order: 26,
    name: "Zuhause, Innenausstattung, Häusliches",
    imageFilename: "category1/ZuhauseInnenausstattungHaeusliches.png",
    category: categories.prideRed,
  }),
  // Category 2
  // **********
  new PictogramTileModel({
    order: 1,
    name: "Wirklichkeit, Geschichte",
    imageFilename: "category2/WirklichkeitGeschichte.png",
    category: categories.prideOrange,
  }),
  new PictogramTileModel({
    order: 2,
    name: "Dichtung, imaginär",
    imageFilename: "category2/DichtungImaginaer.png",
    category: categories.prideOrange,
  }),
  new PictogramTileModel({
    order: 3,
    name: "Langsam, Schildkröte",
    imageFilename: "category2/LangsamSchildkroete.png",
    category: categories.prideOrange,
  }),
  new PictogramTileModel({
    order: 4,
    name: "Schnell, lebhaft, Hase",
    imageFilename: "category2/SchnellLebhaftHase.png",
    category: categories.prideOrange,
  }),
  new PictogramTileModel({
    order: 5,
    name: "Verteidigung, Schutz, Mauer",
    imageFilename: "category2/VerteidigungSchutzMauer.png",
    category: categories.prideOrange,
  }),
  new PictogramTileModel({
    order: 6,
    name: "Konflikt, Waffen, Kampf",
    imageFilename: "category2/KonfliktWaffenKampf.png",
    category: categories.prideOrange,
  }),
  new PictogramTileModel({
    order: 7,
    name: "Leben, Herz, Liebe",
    imageFilename: "category2/LebenHerzLiebe.png",
    category: categories.prideOrange,
  }),
  new PictogramTileModel({
    order: 8,
    name: "Tod, Böse, Krankheit",
    imageFilename: "category2/TodBoeseKrankheit.png",
    category: categories.prideOrange,
  }),
  new PictogramTileModel({
    order: 9,
    name: "Fröhlich, positiv",
    imageFilename: "category2/FroehlichPositiv.png",
    category: categories.prideOrange,
  }),
  new PictogramTileModel({
    order: 10,
    name: "Traurig, Negativ",
    imageFilename: "category2/TraurigNegativ.png",
    category: categories.prideOrange,
  }),
  new PictogramTileModel({
    order: 11,
    name: "Elektronik, Computer",
    imageFilename: "category2/ElektronikComputer.png",
    category: categories.prideOrange,
  }),
  new PictogramTileModel({
    order: 12,
    name: "Mechanik",
    imageFilename: "category2/Mechanik.png",
    category: categories.prideOrange,
  }),
  new PictogramTileModel({
    order: 13,
    name: "Geld, reich, teuer",
    imageFilename: "category2/GeldReichTeuer.png",
    category: categories.prideOrange,
  }),
  new PictogramTileModel({
    order: 14,
    name: "Zeit, Dauer",
    imageFilename: "category2/ZeitDauer.png",
    category: categories.prideOrange,
  }),
  new PictogramTileModel({
    order: 15,
    name: "Religion, Mystik, Glaube",
    imageFilename: "category2/ReligionMystikGlaube.png",
    category: categories.prideOrange,
  }),
  new PictogramTileModel({
    order: 16,
    name: "Macht, Politik",
    imageFilename: "category2/MachtPolitik.png",
    category: categories.prideOrange,
  }),
  new PictogramTileModel({
    order: 17,
    name: "Wissenschaft, Mathematik, Chemie",
    imageFilename: "category2/WissenschaftMathematikChemie.png",
    category: categories.prideOrange,
  }),
  new PictogramTileModel({
    order: 18,
    name: "Medizin, Medikamente, Heilung",
    imageFilename: "category2/MedizinMedikamenteHeilung.png",
    category: categories.prideOrange,
  }),
  // Category 3
  // **********
  new PictogramTileModel({
    order: 1,
    name: "Frau, weiblich",
    imageFilename: "category3/FrauWeiblich.png",
    category: categories.prideYellow,
  }),
  new PictogramTileModel({
    order: 2,
    name: "Mann, männlich",
    imageFilename: "category3/MannMaennlich.png",
    category: categories.prideYellow,
  }),
  new PictogramTileModel({
    order: 3,
    name: "Baby, Kind, jung, neu",
    imageFilename: "category3/BabyKindJungNeu.png",
    category: categories.prideYellow,
  }),
  new PictogramTileModel({
    order: 4,
    name: "Erwachsen, alt, antik, Vergangenheit",
    imageFilename: "category3/ErwachsenAltAntikVergangenheit.png",
    category: categories.prideYellow,
  }),
  new PictogramTileModel({
    order: 5,
    name: "Kopf, Gesicht",
    imageFilename: "category3/KopfGesicht.png",
    category: categories.prideYellow,
  }),
  new PictogramTileModel({
    order: 6,
    name: "Arm, Hand",
    imageFilename: "category3/ArmHand.png",
    category: categories.prideYellow,
  }),
  new PictogramTileModel({
    order: 7,
    name: "Körper, Bauch",
    imageFilename: "category3/KoerperBauch.png",
    category: categories.prideYellow,
  }),
  new PictogramTileModel({
    order: 8,
    name: "Bein, Fuß",
    imageFilename: "category3/BeinFuss.png",
    category: categories.prideYellow,
  }),
  new PictogramTileModel({
    order: 9,
    name: "Ohr, Klang, hören",
    imageFilename: "category3/OhrKlangHoeren.png",
    category: categories.prideYellow,
  }),
  new PictogramTileModel({
    order: 10,
    name: "Nase, Duft, Geruch",
    imageFilename: "category3/NaseDuftGeruch.png",
    category: categories.prideYellow,
  }),
  new PictogramTileModel({
    order: 11,
    name: "Auge, Blick, schauen",
    imageFilename: "category3/AugeBlickSchauen.png",
    category: categories.prideYellow,
  }),
  new PictogramTileModel({
    order: 12,
    name: "Mund, Geschmack, Essen",
    imageFilename: "category3/MundGeschmackEssen.png",
    category: categories.prideYellow,
  }),
  // Category 4
  // **********
  new PictogramTileModel({
    order: 1,
    name: "Wolke, Regen, Schnee, kalt",
    imageFilename: "category4/WolkeRegenSchneeKalt.png",
    category: categories.prideGreen,
  }),
  new PictogramTileModel({
    order: 2,
    name: "Blitz, Elektrizität, Sturm, Wut",
    imageFilename: "category4/BlitzElektrizitaetSturmWut.png",
    category: categories.prideGreen,
  }),
  new PictogramTileModel({
    order: 3,
    name: "Nacht, Abend, Mond",
    imageFilename: "category4/NachtAbendMond.png",
    category: categories.prideGreen,
  }),
  new PictogramTileModel({
    order: 4,
    name: "Sonne, Licht, Tageszeit",
    imageFilename: "category4/SonneLichtTageszeit.png",
    category: categories.prideGreen,
  }),
  new PictogramTileModel({
    order: 5,
    name: "Feuer, brennen",
    imageFilename: "category4/FeuerBrennen.png",
    category: categories.prideGreen,
  }),
  new PictogramTileModel({
    order: 6,
    name: "Wasser, Flüssigkeit, nass",
    imageFilename: "category4/WasserFluessigkeitNass.png",
    category: categories.prideGreen,
  }),
  new PictogramTileModel({
    order: 7,
    name: "Luft, Wind, blasen",
    imageFilename: "category4/LuftWindBlasen.png",
    category: categories.prideGreen,
  }),
  new PictogramTileModel({
    order: 8,
    name: "Erde",
    imageFilename: "category4/Erde.png",
    category: categories.prideGreen,
  }),
  new PictogramTileModel({
    order: 9,
    name: "Stein, Mineralien, hart",
    imageFilename: "category4/SteinMineralienHart.png",
    category: categories.prideGreen,
  }),
  new PictogramTileModel({
    order: 10,
    name: "Holz",
    imageFilename: "category4/Holz.png",
    category: categories.prideGreen,
  }),
  new PictogramTileModel({
    order: 11,
    name: "Metall",
    imageFilename: "category4/Metall.png",
    category: categories.prideGreen,
  }),
  new PictogramTileModel({
    order: 12,
    name: "Stoff",
    imageFilename: "category4/Stoff.png",
    category: categories.prideGreen,
  }),
  new PictogramTileModel({
    order: 13,
    name: "Plastik, Gummi",
    imageFilename: "category4/PlastikGummi.png",
    category: categories.prideGreen,
  }),
  new PictogramTileModel({
    order: 14,
    name: "Papier, Blatt",
    imageFilename: "category4/PapierBlatt.png",
    category: categories.prideGreen,
  }),
  // Category 5
  // **********
  new PictogramTileModel({
    order: 1,
    name: "Gegensätzlich, Gegenteil, invers",
    imageFilename: "category5/GegensaetzlichGegenteilInvers.png",
    category: categories.prideBlue,
  }),
  new PictogramTileModel({
    order: 2,
    name: "Schneiden, getrennt, halb",
    imageFilename: "category5/SchneidenGetrenntHalb.png",
    category: categories.prideBlue,
  }),
  new PictogramTileModel({
    order: 3,
    name: "Bruchstück, Menge, Pulver",
    imageFilename: "category5/BruchstueckMengePulver.png",
    category: categories.prideBlue,
  }),
  new PictogramTileModel({
    order: 4,
    name: "Teil von, Untermenge von, Stück von",
    imageFilename: "category5/TeilVonUntermengeVonStueckVon.png",
    category: categories.prideBlue,
  }),
  new PictogramTileModel({
    order: 5,
    name: "Innenseite, innen",
    imageFilename: "category5/InnenseiteInnen.png",
    category: categories.prideBlue,
  }),
  new PictogramTileModel({
    order: 6,
    name: "Raster, Netzwerk, Gefängnis",
    imageFilename: "category5/RasterNetzwerkGefaengnis.png",
    category: categories.prideBlue,
  }),
  new PictogramTileModel({
    order: 7,
    name: "Null, Nichts, Nullpunkt",
    imageFilename: "category5/NullNichtsNullpunkt.png",
    category: categories.prideBlue,
  }),
  new PictogramTileModel({
    order: 8,
    name: "Einheit, eins",
    imageFilename: "category5/EinheitEins.png",
    category: categories.prideBlue,
  }),
  new PictogramTileModel({
    order: 9,
    name: "Gross, grösser, hoch",
    imageFilename: "category5/GrossGroesserHoch.png",
    category: categories.prideBlue,
  }),
  new PictogramTileModel({
    order: 10,
    name: "Klein, tiefer, unten",
    imageFilename: "category5/KleinTieferUnten.png",
    category: categories.prideBlue,
  }),
  new PictogramTileModel({
    order: 11,
    name: "Riesig, breit, länger",
    imageFilename: "category5/RiesigBreitLaenger.png",
    category: categories.prideBlue,
  }),
  new PictogramTileModel({
    order: 12,
    name: "Schmal, nah, eng",
    imageFilename: "category5/SchmalNahEng.png",
    category: categories.prideBlue,
  }),
  new PictogramTileModel({
    order: 13,
    name: "Spitze, nach oben, aufsteigen",
    imageFilename: "category5/SpitzeNachObenAufsteigen.png",
    category: categories.prideBlue,
  }),
  new PictogramTileModel({
    order: 14,
    name: "Tief, runter, fallen",
    imageFilename: "category5/TiefRunterFallen.png",
    category: categories.prideBlue,
  }),
  new PictogramTileModel({
    order: 15,
    name: "Links, erster, vor",
    imageFilename: "category5/LinksErsterVor.png",
    category: categories.prideBlue,
  }),
  new PictogramTileModel({
    order: 16,
    name: "Rechts, Ende, nach",
    imageFilename: "category5/RechtsEndeNach.png",
    category: categories.prideBlue,
  }),
  new PictogramTileModel({
    order: 17,
    name: "Drehung, drumherum, Kreis",
    imageFilename: "category5/DrehungDrumherumKreis.png",
    category: categories.prideBlue,
  }),
  new PictogramTileModel({
    order: 18,
    name: "Benutzen, aktivieren, machen, handeln",
    imageFilename: "category5/BenutzenAktivierenMachenHandeln.png",
    category: categories.prideBlue,
  }),
  // Category 6
  // **********
  new PictogramTileModel({
    order: 1,
    name: "Gerade, glatt",
    imageFilename: "category6/GeradeGlatt.png",
    category: categories.pridePurple,
  }),
  new PictogramTileModel({
    order: 2,
    name: "Kurve, Bogen, rund",
    imageFilename: "category6/KurveBogenRund.png",
    category: categories.pridePurple,
  }),
  new PictogramTileModel({
    order: 3,
    name: "Kreuz, Kreuzung",
    imageFilename: "category6/KreuzKreuzung.png",
    category: categories.pridePurple,
  }),
  new PictogramTileModel({
    order: 4,
    name: "Gezackte Linie, scharf, au",
    imageFilename: "category6/GezackteLinieScharfRau.png",
    category: categories.pridePurple,
  }),
  new PictogramTileModel({
    order: 5,
    name: "Spirale, Rausch, Wahnsinn, Locke",
    imageFilename: "category6/SpiraleRauschWahnsinnLocke.png",
    category: categories.pridePurple,
  }),
  new PictogramTileModel({
    order: 6,
    name: "Sinusfoermig, wellig, Haar, Fluss",
    imageFilename: "category6/SinusfoermigWelligHaarFluss.png",
    category: categories.pridePurple,
  }),
  new PictogramTileModel({
    order: 7,
    name: "Ring",
    imageFilename: "category6/Ring.png",
    category: categories.pridePurple,
  }),
  new PictogramTileModel({
    order: 8,
    name: "Kreis",
    imageFilename: "category6/Kreis.png",
    category: categories.pridePurple,
  }),
  new PictogramTileModel({
    order: 9,
    name: "Dreieck",
    imageFilename: "category6/Dreieck.png",
    category: categories.pridePurple,
  }),
  new PictogramTileModel({
    order: 10,
    name: "Stern",
    imageFilename: "category6/Stern.png",
    category: categories.pridePurple,
  }),
  new PictogramTileModel({
    order: 11,
    name: "Rechteck, Viereck",
    imageFilename: "category6/RechteckViereck.png",
    category: categories.pridePurple,
  }),
  new PictogramTileModel({
    order: 12,
    name: "Flach",
    imageFilename: "category6/Flach.png",
    category: categories.pridePurple,
  }),
  new PictogramTileModel({
    order: 13,
    name: "Würfel, Backstein",
    imageFilename: "category6/WuerfelBackstein.png",
    category: categories.pridePurple,
  }),
  new PictogramTileModel({
    order: 14,
    name: "Kugel",
    imageFilename: "category6/Kugel.png",
    category: categories.pridePurple,
  }),
  new PictogramTileModel({
    order: 15,
    name: "Pyramide",
    imageFilename: "category6/Pyramide.png",
    category: categories.pridePurple,
  }),
  new PictogramTileModel({
    order: 16,
    name: "Zylinder",
    imageFilename: "category6/Zylinder.png",
    category: categories.pridePurple,
  }),
  new PictogramTileModel({
    order: 17,
    name: "Kegel",
    imageFilename: "category6/Kegel.png",
    category: categories.pridePurple,
  }),
  new PictogramTileModel({
    order: 18,
    name: "Aussparung, Loch, durchbohrt",
    imageFilename: "category6/AussparungLochDurchbohrt.png",
    category: categories.pridePurple,
  }),
  new PictogramTileModel({
    order: 19,
    name: "Rot",
    imageFilename: "category6/rot.png",
    category: categories.pridePurple,
  }),
  new PictogramTileModel({
    order: 20,
    name: "Orange",
    imageFilename: "category6/orange.png",
    category: categories.pridePurple,
  }),
  new PictogramTileModel({
    order: 21,
    name: "Gelb",
    imageFilename: "category6/gelb.png",
    category: categories.pridePurple,
  }),
  new PictogramTileModel({
    order: 22,
    name: "Grün",
    imageFilename: "category6/gruen.png",
    category: categories.pridePurple,
  }),
  new PictogramTileModel({
    order: 23,
    name: "Blau",
    imageFilename: "category6/blau.png",
    category: categories.pridePurple,
  }),
  new PictogramTileModel({
    order: 24,
    name: "Lila",
    imageFilename: "category6/lila.png",
    category: categories.pridePurple,
  }),
  new PictogramTileModel({
    order: 25,
    name: "Rosa",
    imageFilename: "category6/rosa.png",
    category: categories.pridePurple,
  }),
  new PictogramTileModel({
    order: 26,
    name: "Braun",
    imageFilename: "category6/braun.png",
    category: categories.pridePurple,
  }),
  new PictogramTileModel({
    order: 27,
    name: "Schwarz",
    imageFilename: "category6/schwarz.png",
    category: categories.pridePurple,
  }),
  new PictogramTileModel({
    order: 28,
    name: "Grau",
    imageFilename: "category6/grau.png",
    category: categories.pridePurple,
  }),
  new PictogramTileModel({
    order: 29,
    name: "Weiß",
    imageFilename: "category6/weiss.png",
    category: categories.pridePurple,
  }),
  new PictogramTileModel({
    order: 30,
    name: "Klar, unsichtbar, Glas",
    imageFilename: "category6/klarUnsichtbarGlas.png",
    category: categories.pridePurple,
  }),
]);

export default tiles;
