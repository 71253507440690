import { HerdleType } from "@/models/board";

const herdles: Record<string, HerdleType> = {
  "2022-03-27": {
    title: "#0 -Test",
    mainConceptName: "Objekt, Verpackung, Karton",
    tileNames: new Set<string>([
      "Geld, reich, teuer",
      "Feuer, brennen",
      "Mund, Geschmack, Essen",
      "Braun",
      "Gerade, glatt",
    ]),
    word: "Zigarre",
  },
  "2022-03-28": {
    title: "#1 - Esel spezial",
    mainConceptName: "Tierwelt, Tier",
    tileNames: new Set<string>([
      "Langsam, Schildkröte",
      "Fröhlich, positiv",
      "Null, Nichts, Nullpunkt",
      "Benutzen, aktivieren, machen, handeln",
      "Grau",
    ]),
    word: "Esel",
  },
  "2022-03-29": {
    title: "#2",
    mainConceptName: "Arbeit, Handwerk",
    tileNames: new Set<string>([
      "Frau, weiblich",
      "Mann, männlich",
      "Kleidung, Accessoires, Kostüm",
      "Auge, Blick, schauen",
      "Schmal, nah, eng",
    ]),
    word: "Model",
  },
  "2022-03-30": {
    title: "#3",
    mainConceptName: "Pflanzenwelt, Pflanze",
    tileNames: new Set<string>([
      "Essen, Lebensmittel, Essbares",
      "Feuer, brennen",
      "Kegel",
      "Klein, tiefer, unten",
      "Weiß",
    ]),
    word: "Rettich",
  },
  "2022-03-31": {
    title: "#4",
    mainConceptName: "Spiele, Spielzeug",
    tileNames: new Set<string>([
      "Baby, Kind, jung, neu",
      "Fröhlich, positiv",
      "Kegel",
      "Drehung, drumherum, Kreis",
      "Klein, tiefer, unten",
    ]),
    word: "Kreisel",
  },
  "2022-04-01": {
    title: "#5",
    mainConceptName: "Objekt, Verpackung, Karton",
    tileNames: new Set<string>([
      "Zuhause, Innenausstattung, Häusliches",
      "Bein, Fuß",
      "Holz",
      "Gerade, glatt",
      "Flach",
    ]),
    word: "Tisch",
  },
  "2022-04-02": {
    title: "#6",
    mainConceptName: "Kleidung, Accessoires, Kostüm",
    tileNames: new Set<string>([
      "Baby, Kind, jung, neu",
      "Körper, Bauch",
      "Plastik, Gummi",
      "Stoff",
      "Weiß",
    ]),
    word: "Windel",
  },
  "2022-04-03": {
    title: "#7",
    mainConceptName: "Objekt, Verpackung, Karton",
    tileNames: new Set<string>([
      "Geld, reich, teuer",
      "Feuer, brennen",
      "Mund, Geschmack, Essen",
      "Braun",
      "Gerade, glatt",
    ]),
    word: "Zigarre",
  },
  "2022-04-04": {
    title: "#8",
    mainConceptName: "Objekt, Verpackung, Karton",
    tileNames: new Set<string>([
      "Landfahrzeug, Auto, Fahren",
      "Gebäude, Bauwerk, Stadt",
      "Geld, reich, teuer",
      "Zeit, Dauer",
      "Metall",
    ]),
    word: "Parkuhr",
  },
  "2022-04-05": {
    title: "#9",
    mainConceptName: "Tierwelt, Tier",
    tileNames: new Set<string>([
      "Luftfahrzeug, Fluglinie, Fliegen",
      "Fröhlich, positiv",
      "Wolke, Regen, Schnee, kalt",
      "Schwarz",
      "Weiß",
    ]),
    word: "Pinguin",
  },
  "2022-04-06": {
    title: "#10",
    mainConceptName: "Wasser, Flüssigkeit, nass",
    tileNames: new Set<string>([
      "Mann, männlich",
      "Frau, weiblich",
      "Fröhlich, positiv",
      "Geld, reich, teuer",
      "Nase, Duft, Geruch",
    ]),
    word: "Parfüm",
  },
  "2022-04-07": {
    title: "#11",
    mainConceptName: "Dichtung, imaginär",
    tileNames: new Set<string>([
      "Mann, männlich",
      "Holz",
      "Gerade, glatt",
      "Riesig, breit, länger",
      "Nase, Duft, Geruch",
    ]),
    word: "Pinocchio",
  },
  "2022-04-08": {
    title: "#12",
    mainConceptName: "Objekt, Verpackung, Karton",
    tileNames: new Set<string>([
      "Landfahrzeug, Auto, Fahren",
      "Bein, Fuß",
      "Flach",
      "Schwarz",
      "Weiß",
    ]),
    word: "Zebrastreifen",
  },
  "2022-04-09": {
    title: "#13",
    mainConceptName: "Arbeit, Handwerk",
    tileNames: new Set<string>([
      "Wasserfahrzeug, Meer, Schwimmen",
      "Geld, reich, teuer",
      "Konflikt, Waffen, Kampf",
      "Tod, Böse, Krankheit",
      "Dichtung, imaginär",
    ]),
    word: "Pirat",
  },
  "2022-04-10": {
    title: "#14",
    mainConceptName: "Tierwelt, Tier",
    tileNames: new Set<string>([
      "Schnell, lebhaft, Hase",
      "Konflikt, Waffen, Kampf",
      "Gross, grösser, hoch",
      "Tod, Böse, Krankheit",
      "Schwarz",
    ]),
    word: "Panther",
  },
  "2022-04-11": {
    title: "#15",
    mainConceptName: "Familie, Gesellschaft, Gruppe",
    tileNames: new Set<string>([
      "Musik, Lied, Note",
      "Erwachsen, alt, antik, Vergangenheit",
      "Kleidung, Accessoires, Kostüm",
      "Schwarz",
      "Weiß",
    ]),
    word: "Orchester",
  },
  "2022-04-12": {
    title: "#16",
    mainConceptName: "Tierwelt, Tier",
    tileNames: new Set<string>([
      "Luftfahrzeug, Fluglinie, Fliegen",
      "Leben, Herz, Liebe",
      "Körper, Bauch",
      "Sinusfoermig, wellig, Haar, Fluss",
      "Klein, tiefer, unten",
    ]),
    word: "Schmetterling",
  },
  "2022-04-13": {
    title: "#17",
    mainConceptName: "Objekt, Verpackung, Karton",
    tileNames: new Set<string>([
      "Fernsehen, Rundfunk, Serien",
      "Elektronik, Computer",
      "Wissenschaft, Mathematik, Chemie",
      "Null, Nichts, Nullpunkt",
      "Einheit, eins",
    ]),
    word: "Computer",
  },
  "2022-04-14": {
    title: "#8",
    mainConceptName: "Objekt, Verpackung, Karton",
    tileNames: new Set<string>([
      "Landfahrzeug, Auto, Fahren",
      "Gebäude, Bauwerk, Stadt",
      "Geld, reich, teuer",
      "Zeit, Dauer",
      "Metall",
    ]),
    word: "Parkuhr",
  },
  "2022-04-17": {
    title: "#18",
    mainConceptName: "Objekt, Verpackung, Karton",
    tileNames: new Set<string>([
      "Verteidigung, Schutz, Mauer",
      "Wolke, Regen, Schnee, kalt",
      "Plastik, Gummi",
      "Kegel",
      "Gerade, glatt",
    ]),
    word: "Regenschirm",
  },
  "2022-04-18": {
    title: "#19",
    mainConceptName: "Spiele, Spielzeug",
    tileNames: new Set<string>([
      "Tierwelt, Tier",
      "Leben, Herz, Liebe",
      "Fröhlich, positiv",
      "Stoff",
      "Braun",
    ]),
    word: "Teddybär",
  },
  "2022-04-19": {
    title: "#20",
    mainConceptName: "Spiele, Spielzeug",
    tileNames: new Set<string>([
      "Elektronik, Computer",
      "Erwachsen, alt, antik, Vergangenheit",
      "Kreis",
      "Mund, Geschmack, Essen",
      "Gelb",
    ]),
    word: "Pac-Man",
  },
  "2022-04-20": {
    title: "#21",
    mainConceptName: "Objekt, Verpackung, Karton",
    tileNames: new Set<string>([
      "Zuhause, Innenausstattung, Häusliches",
      "Blitz, Elektrizität, Sturm, Wut",
      "Metall",
      "Spitze, nach oben, aufsteigen",
      "Gerade, glatt",
    ]),
    word: "Blitzableiter",
  },
  "2022-04-21": {
    title: "#22",
    mainConceptName: "Mechanik",
    tileNames: new Set<string>([
      "Elektronik, Computer",
      "Musik, Lied, Note",
      "Metall",
      "Gerade, glatt",
      "Kugel",
    ]),
    word: "Mikrofon",
  },
  "2022-04-22": {
    title: "#23",
    mainConceptName: "Objekt, Verpackung, Karton",
    tileNames: new Set<string>([
      "Tierwelt, Tier",
      "Zuhause, Innenausstattung, Häusliches",
      "Gegensätzlich, Gegenteil, invers",
      "Innenseite, innen",
      "Braun",
    ]),
    word: "Hundehütte",
  },
  "2022-04-23": {
    title: "#24",
    mainConceptName: "Kleidung, Accessoires, Kostüm",
    tileNames: new Set<string>([
      "Medizin, Medikamente, Heilung",
      "Mund, Geschmack, Essen",
      "Nase, Duft, Geruch",
      "Stoff",
      "Weiß",
    ]),
    word: "Maske",
  },
  "2022-04-24": {
    title: "#25",
    mainConceptName: "Zuhause, Innenausstattung, Häusliches",
    tileNames: new Set<string>([
      "Luftfahrzeug, Fluglinie, Fliegen",
      "Tierwelt, Tier",
      "Innenseite, innen",
      "Kugel",
      "Holz",
    ]),
    word: "Nest",
  },
  "2022-04-25": {
    title: "#26",
    mainConceptName: "Erholung, Sport ,Aktivität",
    tileNames: new Set<string>([
      "Wirklichkeit, Geschichte",
      "Schnell, lebhaft, Hase",
      "Konflikt, Waffen, Kampf",
      "Stern",
      "Schwarz",
    ]),
    word: "Ninja",
  },
  "2022-04-26": {
    title: "#27",
    mainConceptName: "Essen, Lebensmittel, Essbares",
    tileNames: new Set<string>([
      "Kugel",
      "Tierwelt, Tier",
      "Innenseite, innen",
      "Gelb",
      "Weiß",
    ]),
    word: "Ei",
  },
  "2022-04-27": {
    title: "#28",
    mainConceptName: "Tierwelt, Tier",
    tileNames: new Set<string>([
      "Luftfahrzeug, Fluglinie, Fliegen",
      "Klein, tiefer, unten",
      "Gerade, glatt",
      "Blau",
      "Klar, unsichtbar, Glas",
    ]),
    word: "Libelle",
  },
  "2022-04-28": {
    title: "#29",
    mainConceptName: "Dichtung, imaginär",
    tileNames: new Set<string>([
      "Literatur, Schrift, Buch",
      "Theater, Film, Kamera",
      "Traurig, Negativ",
      "Gross, grösser, hoch",
      "Grün",
    ]),
    word: "Oger",
  },
  "2022-04-29": {
    title: "#30",
    mainConceptName: "Objekt, Verpackung, Karton",
    tileNames: new Set<string>([
      "Verteidigung, Schutz, Mauer",
      "Sonne, Licht, Tageszeit",
      "Plastik, Gummi",
      "Kegel",
      "Gerade, glatt",
    ]),
    word: "Sonnenschirm",
  },
  "2024-03-27": {
    title: "#31 - Schweini spezial",
    mainConceptName: "Tierwelt, Tier",
    tileNames: new Set<string>([
      "Idee, Gedanke, Konzept",
      "Fröhlich, positiv",
      "Nase, Duft, Geruch",
      "Erde",
      "Rosa",
    ]),
    word: "Schweini",
  },
  "2024-03-28": {
    title: "#31 - Schweini spezial",
    mainConceptName: "Tierwelt, Tier",
    tileNames: new Set<string>([
      "Idee, Gedanke, Konzept",
      "Fröhlich, positiv",
      "Nase, Duft, Geruch",
      "Erde",
      "Rosa",
    ]),
    word: "Schweini",
  },
};
export default herdles;
