











import { Component, Prop, Vue } from "vue-property-decorator";
import { BRow } from "bootstrap-vue";
import { GameBoardTileModel } from "@/models/board";
import GameBoardTileComponent from "@/components/board/GameBoardTileComponent.vue";
import { gameBoardStore } from "@/store/board.vuex";

@Component({
  components: {
    BRow,
    GameBoardTileComponent,
  },
})
export default class TileCategoryComponent extends Vue {
  @Prop({ required: true }) readonly tiles!: Array<GameBoardTileModel>;

  get colorClass(): string {
    if (this.tiles[0]) {
      return this.tiles[0].pictogramTile.category.colorClass;
    } else {
      return "";
    }
  }
  onClick(tile: GameBoardTileModel) {
    gameBoardStore.addTile(tile);
  }
}
