import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import { config } from "vuex-module-decorators";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ["settings"],
});

// Set rawError to true by default on all @Action decorators
config.rawError = true;

export default new Vuex.Store({
  state: {},
  modules: {},
  plugins: [vuexLocal.plugin],
});
