import {
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import seedrandom from "seedrandom";
import store from "@/store";
import {
  gameBoardFactory,
  GameBoardModel,
  GameBoardTileModel,
  nullGameBoardTile,
  nullPictogramTileModel,
  numberOfColumns,
  numberOfSelectedTilesInCategory,
  PictogramTileModel,
  HerdleType,
  TileCheckResultState,
  uncheckedTileCheckResult,
} from "@/models/board";
import { RowFullError } from "@/errors";
import { tilesStore } from "@/store/tiles.vuex";
import Vue from "vue";
import herdles from "@/store/seeds/herdles";

const moduleName = "gameBoard";

@Module({
  dynamic: true,
  store,
  name: moduleName,
  namespaced: true,
  //preserveState: localStorage.getItem("vuex") !== null,
})
export class GameBoardStore extends VuexModule {
  private static readonly HINT_LIMIT = 2;

  private readonly _todayISOString = new Date().toISOString().substring(0, 10);

  private _herdles = herdles;
  private _herdle: HerdleType = herdles[this._todayISOString];
  private _selectedTiles: Array<Array<GameBoardTileModel>> = [];

  readonly solutionTiles: Array<PictogramTileModel> =
    GameBoardStore.initSolutionTiles(this._herdle.tileNames);

  gameBoard: GameBoardModel = gameBoardFactory();
  activeRowIndex = 0;
  isTilesSolved = false;
  isWordSolved = false;
  guessCount = 0;
  showEasterEgg = false;
  isWordInputVisible = false;
  wordHintCoveredIndex: Array<number> = GameBoardStore.initWordHintIndex(
    this._herdle.word
  );

  get mainConceptTile(): PictogramTileModel {
    return tilesStore.tileByName(this._herdle.mainConceptName);
  }

  get selectedTiles(): Array<Array<GameBoardTileModel>> {
    if (this._selectedTiles.length === 0) {
      const result: Array<Array<GameBoardTileModel>> = [];
      const seededRNG = seedrandom(this._herdle.word);
      for (const [, category] of Object.entries(tilesStore.categories)) {
        const row: Array<GameBoardTileModel> = [];
        const pictogramTiles = Array.from(category.pictogramTiles);
        const solutionTilesInCategory = this.solutionTiles.filter(
          (tile) => tile.category === category
        );
        if (this.mainConceptTile.category === category) {
          GameBoardStore.pullTileFromArray(
            this.mainConceptTile,
            pictogramTiles
          );
        }
        for (const tile of solutionTilesInCategory) {
          row.push({
            pictogramTile: GameBoardStore.pullTileFromArray(
              tile,
              pictogramTiles
            ),
            checkResult: uncheckedTileCheckResult,
          });
        }
        while (row.length < numberOfSelectedTilesInCategory) {
          row.push({
            pictogramTile: GameBoardStore.pullRandomTileFromArray(
              seededRNG(),
              pictogramTiles
            ),
            checkResult: uncheckedTileCheckResult,
          });
        }
        row.sort((a, b) =>
          a.pictogramTile.order < b.pictogramTile.order ? -1 : 1
        );
        result.push(row);
      }
      this._selectedTiles.push(...result);
    }
    return this._selectedTiles;
  }

  get renderBoard(): Array<Array<GameBoardTileModel>> {
    const result: Array<Array<GameBoardTileModel>> = [];
    this.gameBoard.forEach((row) => {
      const renderedRow = Array.from(row.values());
      const emptyTiles = numberOfColumns - row.size;
      // Fill array with null tiles
      renderedRow.push(...new Array(emptyTiles).fill(nullGameBoardTile));
      result.push(renderedRow);
    });
    return result;
  }

  get isActiveRowReadyForSubmit(): boolean {
    return (
      !this.isTilesSolved &&
      this.gameBoard[this.activeRowIndex].size === numberOfColumns
    );
  }

  get solutionWord(): string {
    return this._herdle.word;
  }

  get herdleTitle(): string {
    return this._herdle.title;
  }

  get correctTiles(): Array<GameBoardTileModel> {
    const flattenedTiles = this.selectedTiles.flat(2);
    return flattenedTiles.filter((tile) => {
      return tile.checkResult.state === TileCheckResultState.CORRECT;
    });
  }

  get wordHint(): string {
    const wordHintChars = this._herdle.word.toUpperCase().split("");
    this.wordHintCoveredIndex.forEach((index) => (wordHintChars[index] = "_"));
    return wordHintChars.join("");
  }

  @Mutation
  addTile(tile: GameBoardTileModel): void {
    const activeRow = this.gameBoard[this.activeRowIndex];
    if (activeRow.size < numberOfColumns) {
      activeRow.add(tile);
      Vue.set(this.gameBoard, this.activeRowIndex, activeRow);
    } else {
      throw new RowFullError();
    }
  }

  @Mutation
  removeTileFromActiveRow(tile: GameBoardTileModel) {
    if (!this.isTilesSolved) {
      const activeRow = this.gameBoard[this.activeRowIndex];
      activeRow.delete(tile);
      Vue.set(this.gameBoard, this.activeRowIndex, activeRow);
    }
  }

  @Mutation
  submitActiveRow() {
    const correctCount = GameBoardStore.checkRow(
      this.gameBoard[this.activeRowIndex],
      this.solutionTiles
    );
    if (correctCount === numberOfColumns) {
      this.isTilesSolved = true;
    } else {
      this.activeRowIndex++;
    }
  }

  @Mutation
  submitWordGuess(guess: string) {
    const seededRNG = seedrandom(this._herdle.word);
    if (this.isTilesSolved) {
      this.guessCount++;
    }
    if (guess.toLowerCase().trim() === this._herdle.word.toLowerCase()) {
      this.isWordSolved = true;
      this.wordHintCoveredIndex = [];
      if (this._herdle.word.toLowerCase() === "schweini") {
        this.showEasterEgg = true;
      }
    } else if (
      this.guessCount > GameBoardStore.HINT_LIMIT &&
      this.wordHintCoveredIndex.length > 1
    ) {
      const index = Math.floor(seededRNG() * this.wordHintCoveredIndex.length);
      this.wordHintCoveredIndex.splice(index, 1);
    }
  }

  @Mutation
  showWordInput() {
    this.isWordInputVisible = true;
  }

  @Mutation
  showPictogramInput() {
    this.isWordInputVisible = false;
  }

  @Mutation
  hideEasterEgg() {
    this.showEasterEgg = false;
  }

  private static initSolutionTiles(
    tileNames: Set<string>
  ): Array<PictogramTileModel> {
    const result: Array<PictogramTileModel> = [];
    for (const tileName of tileNames) {
      result.push(tilesStore.tileByName(tileName));
    }
    return result;
  }

  private static initWordHintIndex(solutionWord: string) {
    return [...Array(solutionWord.length).keys()];
  }

  private static pullTileFromArray(
    tile: PictogramTileModel,
    pictogramTiles: Array<PictogramTileModel>
  ): PictogramTileModel {
    const index = pictogramTiles.indexOf(tile);
    return pictogramTiles.splice(index, 1).pop() || nullPictogramTileModel;
  }

  private static pullRandomTileFromArray(
    randomNumber: number,
    pictogramTiles: Array<PictogramTileModel>
  ): PictogramTileModel {
    const index = Math.floor(randomNumber * pictogramTiles.length);
    return pictogramTiles.splice(index, 1).pop() || nullPictogramTileModel;
  }

  private static checkRow(
    row: Set<GameBoardTileModel>,
    solutionTiles: Array<PictogramTileModel>
  ) {
    let correctCount = 0;
    for (const tile of row) {
      if (tile.checkResult.state === TileCheckResultState.CORRECT) {
        correctCount++;
      }
      if (tile.checkResult.state === TileCheckResultState.UNCHECKED) {
        const solutionTilesWithSameCategory = solutionTiles.filter(
          (solutionTile) => {
            return solutionTile.category === tile.pictogramTile.category;
          }
        );
        const membersInCategory = solutionTilesWithSameCategory.length;
        if (solutionTiles.includes(tile.pictogramTile)) {
          tile.checkResult = {
            state: TileCheckResultState.CORRECT,
            membersInCategory: membersInCategory,
          };
          correctCount++;
        } else {
          if (membersInCategory > 0) {
            tile.checkResult = {
              state: TileCheckResultState.CORRECT_CATEGORY,
              membersInCategory: membersInCategory,
            };
          } else {
            tile.checkResult = {
              state: TileCheckResultState.INCORRECT,
              membersInCategory: 0,
            };
          }
        }
      }
    }
    return correctCount;
  }
}

export const gameBoardStore = getModule(GameBoardStore);
