import { Module, VuexModule, getModule } from "vuex-module-decorators";
import categories from "@/store/seeds/categories";
import tiles from "@/store/seeds/tiles";
import { nullPictogramTileModel, PictogramTileModel } from "@/models/board";
import store from "@/store/index";

const moduleName = "tiles";

@Module({ dynamic: true, store, name: moduleName, namespaced: true })
export class TilesStore extends VuexModule {
  readonly categories = categories;
  readonly tilesArray: Array<PictogramTileModel> = Array.from(tiles);

  get currentTiles(): Array<PictogramTileModel> {
    return this.tilesArray;
  }

  get tileByName(): (name: string) => PictogramTileModel {
    return (name: string): PictogramTileModel => {
      return (
        this.tilesArray.find((tile) => tile.name === name) ||
        nullPictogramTileModel
      );
    };
  }
}

export const tilesStore = getModule(TilesStore);
