











import { Component, Vue } from "vue-property-decorator";
import { gameBoardStore } from "@/store/board.vuex";
import { BIcon } from "bootstrap-vue";

@Component({
  components: {
    BIcon,
  },
})
export default class EasterEggComponent extends Vue {
  hideEasterEgg() {
    gameBoardStore.hideEasterEgg();
  }
}
