




import { Component, Vue, Prop } from "vue-property-decorator";
import { PictogramTileModel } from "@/models/board";

@Component({
  components: {},
})
export default class PictogramTileComponent extends Vue {
  @Prop() pictogramTile!: PictogramTileModel;

  get tileImage() {
    return require(`@/assets/tiles/${this.pictogramTile.imageFilename}`);
  }
}
