type Tuple<T, N extends number> = N extends N
  ? number extends N
    ? T[]
    : _TupleOf<T, N, []>
  : never;
type _TupleOf<T, N extends number, R extends unknown[]> = R["length"] extends N
  ? R
  : _TupleOf<T, N, [T, ...R]>;

export class TileCategory {
  private readonly _colorClass: string;
  private readonly _pictogramTiles: Set<PictogramTileModel>;
  constructor({ colorClass }: { colorClass: string }) {
    this._colorClass = colorClass;
    this._pictogramTiles = new Set([]);
  }

  add(pictogramTile: PictogramTileModel) {
    this._pictogramTiles.add(pictogramTile);
  }

  get colorClass(): string {
    return this._colorClass;
  }

  get pictogramTiles() {
    return this._pictogramTiles;
  }
}

export const numberOfSelectedTilesInCategory = 5;

const nullTileCategory = new TileCategory({ colorClass: "white" });

export class PictogramTileModel {
  private readonly BASE_PATH = "./assets/tiles";

  private readonly _order: number;
  private readonly _name: string;
  private readonly _category: TileCategory;
  private readonly _imageFilename: string;

  constructor({
    order,
    name,
    category,
    imageFilename,
  }: {
    order: number;
    name: string;
    category: TileCategory;
    imageFilename: string;
  }) {
    this._order = order;
    this._name = name;
    this._category = category;
    this._imageFilename = imageFilename;
    category.add(this);
  }

  get order() {
    return this._order;
  }

  get imageFilename(): string {
    return this._imageFilename;
  }
  get category(): TileCategory {
    return this._category;
  }
  get name(): string {
    return this._name;
  }
}

export const nullPictogramTileModel = new PictogramTileModel({
  order: 9999,
  name: "Leere Kachel",
  category: nullTileCategory,
  imageFilename: "placeholder.png",
});

export enum TileCheckResultState {
  UNCHECKED,
  CORRECT,
  CORRECT_CATEGORY,
  INCORRECT,
}

export type TileCheckResult =
  | { state: TileCheckResultState.UNCHECKED; membersInCategory: undefined }
  | { state: TileCheckResultState.CORRECT; membersInCategory: number }
  | { state: TileCheckResultState.CORRECT_CATEGORY; membersInCategory: number }
  | { state: TileCheckResultState.INCORRECT; membersInCategory: 0 };

export const uncheckedTileCheckResult: TileCheckResult = {
  state: TileCheckResultState.UNCHECKED,
  membersInCategory: undefined,
};

export type GameBoardTileModel = {
  pictogramTile: PictogramTileModel;
  checkResult: TileCheckResult;
};

export const nullGameBoardTile: GameBoardTileModel = {
  pictogramTile: nullPictogramTileModel,
  checkResult: uncheckedTileCheckResult,
};

export const numberOfColumns = 5;

type NumberOfRows<T> = Tuple<T, 5>;
type GameBoardType<P> = NumberOfRows<Set<P>>;

export type GameBoardModel = GameBoardType<GameBoardTileModel>;

export function gameBoardFactory(): GameBoardModel {
  return [new Set([]), new Set([]), new Set([]), new Set([]), new Set([])];
}

export type HerdleType = {
  title: string;
  mainConceptName: string;
  tileNames: Set<string>;
  word: string;
};
