














import { Component, Vue } from "vue-property-decorator";
import { gameBoardStore } from "@/store/board.vuex";

@Component({
  components: {},
})
export default class CounterComponent extends Vue {
  end = this.tomorrowsDate;

  displayHours: string | number = "00";
  displayMinutes: string | number = "00";
  displaySeconds: string | number = "00";

  get neededRows() {
    return gameBoardStore.activeRowIndex + 1;
  }

  get herdleTitle(): string {
    return gameBoardStore.herdleTitle;
  }

  get tomorrowsDate(): Date {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 2);
    return new Date(tomorrow.setHours(0, 0, 0, 0));
  }

  get countdown(): string {
    return `${this.displayHours}:${this.displayMinutes}:${this.displaySeconds}`;
  }

  showRemaining() {
    const timer = setInterval(() => {
      const seconds = 1000;
      const minutes = seconds * 60;
      const hours = minutes * 60;
      const days = hours * 24;

      const now = new Date();
      const distance = this.end.getTime() - now.getTime();

      if (distance < 0) {
        clearInterval(timer);
        return;
      }

      const displyHours = Math.floor((distance % days) / hours);
      const displyMinutes = Math.floor((distance % hours) / minutes);
      const displySeconds = Math.floor((distance % minutes) / seconds);
      this.displayHours = displyHours < 10 ? "0" + displyHours : displyHours;
      this.displayMinutes =
        displyMinutes < 10 ? "0" + displyMinutes : displyMinutes;
      this.displaySeconds =
        displySeconds < 10 ? "0" + displySeconds : displySeconds;
    }, 1000);
  }

  mounted() {
    this.showRemaining();
  }
}
