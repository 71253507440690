














import { Component, Vue } from "vue-property-decorator";
import { BButton, BButtonGroup, BContainer } from "bootstrap-vue";
import BoardComponent from "@/components/board/BoardComponent.vue";
import EasterEggComponent from "@/components/layout/EasterEggComponent.vue";
import HelpComponent from "@/components/layout/HelpComponent.vue";
import WordInputComponent from "@/components/board/WordInputComponent.vue";
import HeaderComponent from "@/components/layout/HeaderComponent.vue";
import { settingsStore } from "@/store/settings.vuex";
import { gameBoardStore } from "@/store/board.vuex";

@Component({
  components: {
    BButton,
    BButtonGroup,
    BContainer,
    BoardComponent,
    EasterEggComponent,
    HeaderComponent,
    HelpComponent,
    WordInputComponent,
  },
})
export default class App extends Vue {
  get isWordInputVisible(): boolean {
    return gameBoardStore.isWordInputVisible;
  }
  get theme(): string {
    return settingsStore.theme;
  }

  get easterEggComponentVisible(): boolean {
    return gameBoardStore.showEasterEgg;
  }

  get helpComponentVisible(): boolean {
    return settingsStore.isHelpVisible;
  }
}
