import {
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import store from "@/store/index";

const moduleName = "settings";

@Module({
  dynamic: true,
  store,
  name: moduleName,
  namespaced: true,
  preserveState: localStorage.getItem("vuex") !== null,
})
export class SettingsStore extends VuexModule {
  theme = "light-theme";
  isHelpVisible = false;

  @Mutation
  setTheme(theme: string) {
    this.theme = theme;
  }

  @Mutation
  showHelp() {
    this.isHelpVisible = true;
  }

  @Mutation
  hideHelp() {
    this.isHelpVisible = false;
  }
}

export const settingsStore = getModule(SettingsStore);
