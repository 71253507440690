




















































import { Component, Vue } from "vue-property-decorator";
import { BContainer, BIcon } from "bootstrap-vue";
import { settingsStore } from "@/store/settings.vuex";

@Component({
  components: {
    BContainer,
    BIcon,
  },
})
export default class HelpComponent extends Vue {
  hideHelp() {
    settingsStore.hideHelp();
  }
}
