



















import { Component, Vue } from "vue-property-decorator";
import { settingsStore } from "@/store/settings.vuex";

@Component({
  components: {},
})
export default class ThemeButtonComponent extends Vue {
  userTheme = "light-theme";

  mounted() {
    const initUserTheme = this.theme || this.getMediaPreference();
    this.setTheme(initUserTheme);
  }

  toggleTheme() {
    if (this.theme === "light-theme") {
      this.setTheme("dark-theme");
    } else {
      this.setTheme("light-theme");
    }
  }

  get theme() {
    return settingsStore.theme;
  }

  setTheme(theme: string) {
    settingsStore.setTheme(theme);
  }

  getMediaPreference() {
    const hasDarkPreference = window.matchMedia(
      "(prefers-color-scheme: dark)"
    ).matches;
    if (hasDarkPreference) {
      return "dark-theme";
    } else {
      return "light-theme";
    }
  }
}
