















import { Component, Vue, Prop } from "vue-property-decorator";
import { BRow } from "bootstrap-vue";
import { GameBoardTileModel } from "@/models/board";
import GameBoardTileComponent from "@/components/board/GameBoardTileComponent.vue";
import { BButton } from "bootstrap-vue";
import { gameBoardStore } from "@/store/board.vuex";

@Component({
  components: {
    BButton,
    BRow,
    GameBoardTileComponent,
  },
})
export default class GameBoardRowComponent extends Vue {
  @Prop({ required: true }) readonly row!: Array<GameBoardTileModel>;
  @Prop({ default: false }) readonly isActive!: boolean;

  removeTile(tile: GameBoardTileModel) {
    if (this.isActive) {
      gameBoardStore.removeTileFromActiveRow(tile);
    }
  }
}
