




































import { Component, Vue } from "vue-property-decorator";
import PictogramTileComponent from "@/components/board/PictogramTileComponent.vue";
import { BButton, BCol, BContainer, BIcon, BRow } from "bootstrap-vue";
import ThemeButtonComponent from "@/components/layout/ThemeButtonComponent.vue";
import { settingsStore } from "@/store/settings.vuex";

@Component({
  components: {
    BButton,
    BCol,
    BContainer,
    BIcon,
    BRow,
    PictogramTileComponent,
    ThemeButtonComponent,
  },
})
export default class HeaderComponent extends Vue {
  showHelp() {
    settingsStore.showHelp();
  }
}
