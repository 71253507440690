








































import { Component, Vue } from "vue-property-decorator";
import { BContainer } from "bootstrap-vue";
import { gameBoardStore } from "@/store/board.vuex";
import { BButton, BButtonGroup } from "bootstrap-vue";
import GameBoardRowComponent from "@/components/board/GameBoardRowComponent.vue";
import PictogramTileComponent from "@/components/board/PictogramTileComponent.vue";
import SelectorComponent from "@/components/board/SelectorComponent.vue";
import WordInputComponent from "@/components/board/WordInputComponent.vue";
import { PictogramTileModel } from "@/models/board";

@Component({
  components: {
    BButton,
    BButtonGroup,
    BContainer,
    GameBoardRowComponent,
    PictogramTileComponent,
    SelectorComponent,
    WordInputComponent,
  },
})
export default class BoardComponent extends Vue {
  get board() {
    return gameBoardStore.renderBoard;
  }

  get activeRowIndex(): number {
    return gameBoardStore.activeRowIndex;
  }

  get readyForSubmit(): boolean {
    return gameBoardStore.isActiveRowReadyForSubmit;
  }

  get mainConceptTile(): PictogramTileModel {
    return gameBoardStore.mainConceptTile;
  }

  onClick() {
    if (this.readyForSubmit) {
      gameBoardStore.submitActiveRow();
      if (gameBoardStore.isTilesSolved) {
        gameBoardStore.showWordInput();
      }
    }
  }

  showWordInput() {
    gameBoardStore.showWordInput();
  }
}
